import React from 'react';
import type { LocalizedString } from 'typesafe-i18n';

import { wbPlanText } from '../../localization/wbPlan';
import { camelToHuman, spanNumber } from '../../utils/utils';
import { icons } from '../Atoms/Icons';
import { getTable } from '../DataModel/tables';
import type { Tables } from '../DataModel/types';
import { getIcon } from '../InitialContext/icons';
import { SvgIcon } from './SvgIcon';

const MAX_HUE = 360;

/**
 * Convert first 2 characters of a table name to a number [0,255] corresponding
 * to color hue.
 *
 * Used for autogenerated table icons if table icon image is missing.
 */
const getHue = spanNumber(
  // eslint-disable-next-line unicorn/prefer-code-point
  'a'.charCodeAt(0) * 2,
  // eslint-disable-next-line unicorn/prefer-code-point
  'z'.charCodeAt(0) * 2,
  0,
  MAX_HUE
);

/** Generate an HSL color based on the first 2 characters of a string */
export function stringToColor(rawName: string): string {
  const name = rawName.toLowerCase();
  return `hsl(${getHue(
    // eslint-disable-next-line unicorn/prefer-code-point
    (name[0] ?? 'a').charCodeAt(0) + (name[1] ?? 'a').charCodeAt(0)
  )}, 70%, 50%)`;
}

/**
 * Renders a table icon or autogenerate a new one
 */
export function TableIcon({
  name,
  label,
  /**
   * It is highly recommended to use the same icon size everywhere, as that
   * improves consistency, thus, this should be overwritten only if it is
   * strictly necessary.
   */
  className = 'w-table-icon h-table-icon',
}: {
  readonly name: string;
  /**
   * Set this to false only if icon would be rendered adjacent to the table name.
   * In all other cases, set this to true, or explicitly set the label as a
   * string
   */
  readonly label: LocalizedString | boolean;
  readonly className?: string;
}): JSX.Element {
  const table = getTable(name);
  const tableName = table?.name;
  const resolvedTableLabel =
    label === false
      ? undefined
      : typeof label === 'string'
        ? label
        : (table?.label ?? camelToHuman(name));
  if (tableName === undefined) {
    const tableIconSource = getIcon(name);
    // Display a legacy Specify 6 icon
    if (typeof tableIconSource === 'string')
      return (
        <span
          aria-hidden={resolvedTableLabel === undefined}
          aria-label={resolvedTableLabel}
          className={`${className} bg-contain bg-center bg-no-repeat`}
          role={typeof resolvedTableLabel === 'string' ? 'img' : undefined}
          style={{ backgroundImage: `url('${tableIconSource}')` }}
          title={resolvedTableLabel}
        />
      );
  }

  return (
    <SvgIcon
      // Render SvgIcon even for unknown tables, as it can autogenerate an icon
      className={`${className} flex-shrink-0 font-normal not-italic`}
      label={resolvedTableLabel}
      name={tableName ?? (name as keyof Tables)}
    />
  );
}

export const tableIconUndefined = (
  <span
    aria-label={wbPlanText.unmapped()}
    className={`
      h-table-icon w-table-icon flex items-center justify-center font-bold
      text-red-600
    `}
    role="img"
  >
    {icons.ban}
  </span>
);

export const tableIconSelected = (
  <span
    aria-label={wbPlanText.mapped()}
    className={`
      h-table-icon w-table-icon flex items-center justify-center font-bold
      text-green-500
    `}
    role="img"
  >
    {icons.check}
  </span>
);

export const tableIconEmpty = (
  <span aria-hidden className="h-table-icon w-table-icon" />
);
