/**
 * Configuration for what schema tables and fields are accessible in the WB
 *
 * @remarks
 * Replaces the need for separate Workbench Schema like in Specify 6
 *
 * @module
 */

import { f } from '../../utils/functools';
import type { IR, RR } from '../../utils/types';
import { caseInsensitiveHash, VALUE } from '../../utils/utils';
import type { TableFields } from './helperTypes';
import type { Tables } from './types';

export type TableConfigOverwrite =
  /*
   * Adds a table to the list of common base tables (shown on the base table
   *  selection screen even if "Show Advanced Tables" is not checked
   */
  | 'commonBaseTable'
  /*
   * Remove table from the list of base tables, but still make it available
   *  through relationships
   */
  | 'hidden'
  /*
   * Remove the table, all of it's fields/relationships and all
   *  fields/relationships from any table to this table
   */
  | 'system';

type FieldConfigOverwrite = Partial<{
  readonly visibility:
    | 'optional' // Makes a required field optional
    | 'required'
    // Removes a field from the mapper (but not from Query Builder)
    | 'readOnly'
    // Hides a field. If it was required, it is made optional
    | 'hidden';

  // Indicates white space should not be ignored in the field
  readonly whiteSpaceSensitive: true;
}>;

const tableOverwrites: Partial<RR<keyof Tables, TableConfigOverwrite>> = {
  Accession: 'commonBaseTable',
  Agent: 'commonBaseTable',
  Borrow: 'commonBaseTable',
  CollectingEvent: 'commonBaseTable',
  CollectionObject: 'commonBaseTable',
  ConservEvent: 'commonBaseTable',
  Container: 'commonBaseTable',
  Deaccession: 'commonBaseTable',
  Determination: 'commonBaseTable',
  DNASequence: 'commonBaseTable',
  ExchangeIn: 'commonBaseTable',
  ExchangeOut: 'commonBaseTable',
  Geography: 'commonBaseTable',
  Gift: 'commonBaseTable',
  Loan: 'commonBaseTable',
  Locality: 'commonBaseTable',
  Permit: 'commonBaseTable',
  Preparation: 'commonBaseTable',
  Storage: 'commonBaseTable',
  Taxon: 'commonBaseTable',
  TreatmentEvent: 'commonBaseTable',
  CollectingEventAttr: 'system',
  CollectionObjectAttr: 'system',
  LatLonPolygonPnt: 'system',
  Collection: 'system',
  Discipline: 'system',
  Division: 'system',
  Institution: 'system',
  Workbench: 'hidden',
  WorkbenchDataItem: 'hidden',
  WorkbenchRow: 'hidden',
  WorkbenchRowExportedRelationship: 'hidden',
  WorkbenchRowImage: 'hidden',
  WorkbenchTemplate: 'hidden',
  WorkbenchTemplateMappingItem: 'hidden',
  SpVisualQuery: 'hidden',
  SpSymbiotaInstance: 'hidden',
  GeographyTreeDef: 'system',
  GeographyTreeDefItem: 'system',
  GeologicTimePeriodTreeDef: 'system',
  GeologicTimePeriodTreeDefItem: 'system',
  LithoStratTreeDef: 'system',
  LithoStratTreeDefItem: 'system',
  StorageTreeDef: 'system',
  StorageTreeDefItem: 'system',
  TaxonTreeDef: 'system',
  TaxonTreeDefItem: 'system',
  TectonicUnitTreeDef: 'system',
  TectonicUnitTreeDefItem: 'system',
};

// These field overrides apply to entire front-end
const globalFieldOverrides: {
  readonly [TABLE_NAME in keyof Tables]?: {
    readonly [FIELD_NAME in TableFields<
      Tables[TABLE_NAME]
    >]?: FieldConfigOverwrite;
  };
} & {
  readonly common: IR<FieldConfigOverwrite>;
} = {
  // Common overwrites apply to fields in all tables
  common: {
    timestampCreated: { visibility: 'readOnly' },
    /**
     * This is read only in default forms, but not in schema config.
     * That causes problems as field is editable in autogenerated view.
     */
    timestampModified: { visibility: 'readOnly' },
  },
  Attachment: {
    tableID: { visibility: 'optional' },
  },
  CollectionRelationship: {
    collectionRelType: { visibility: 'required' },
  },
  CollectionRelType: {
    name: { visibility: 'required' },
  },
  DNASequence: {
    totalResidues: { visibility: 'readOnly' },
    compA: { visibility: 'readOnly' },
    compG: { visibility: 'readOnly' },
    compC: { visibility: 'readOnly' },
    compT: { visibility: 'readOnly' },
    ambiguousResidues: { visibility: 'readOnly' },
  },
  Taxon: {
    parent: { visibility: 'required' },
    isAccepted: { visibility: 'readOnly' },
    acceptedTaxon: { visibility: 'readOnly' },
    fullName: { visibility: 'readOnly' },
  },
  Geography: {
    parent: { visibility: 'required' },
    isAccepted: { visibility: 'readOnly' },
    acceptedGeography: { visibility: 'readOnly' },
    fullName: { visibility: 'readOnly' },
  },
  LithoStrat: {
    parent: { visibility: 'required' },
    isAccepted: { visibility: 'readOnly' },
    acceptedLithoStrat: { visibility: 'readOnly' },
    fullName: { visibility: 'readOnly' },
  },
  GeologicTimePeriod: {
    parent: { visibility: 'required' },
    isAccepted: { visibility: 'readOnly' },
    acceptedGeologicTimePeriod: { visibility: 'readOnly' },
    fullName: { visibility: 'readOnly' },
  },
  TectonicUnit: {
    parent: { visibility: 'required' },
    isAccepted: { visibility: 'readOnly' },
    acceptedTectonicUnit: { visibility: 'readOnly' },
    fullName: { visibility: 'readOnly' },
  },
  Storage: {
    parent: { visibility: 'required' },
    isAccepted: { visibility: 'readOnly' },
    acceptedStorage: { visibility: 'readOnly' },
    fullName: { visibility: 'readOnly' },
  },
  SpecifyUser: {
    isAdmin: { visibility: 'readOnly' },
    password: { visibility: 'hidden' },
  },
  TaxonTreeDef: {
    fullNameDirection: { visibility: 'readOnly' },
  },
  TaxonTreeDefItem: {
    fullNameSeparator: { whiteSpaceSensitive: true },
  },
  GeographyTreeDef: {
    fullNameDirection: { visibility: 'readOnly' },
  },
  GeographyTreeDefItem: {
    fullNameSeparator: { whiteSpaceSensitive: true },
  },
  StorageTreeDef: {
    fullNameDirection: { visibility: 'readOnly' },
  },
  StorageTreeDefItem: {
    fullNameSeparator: { whiteSpaceSensitive: true },
  },
  GeologicTimePeriodTreeDef: {
    fullNameDirection: { visibility: 'readOnly' },
  },
  GeologicTimePeriodTreeDefItem: {
    fullNameSeparator: { whiteSpaceSensitive: true },
  },
  TectonicUnitTreeDef: {
    fullNameDirection: { visibility: 'readOnly' },
  },
  TectonicUnitTreeDefItem: {
    fullNameSeparator: { whiteSpaceSensitive: true },
  },
  LithoStratTreeDef: {
    fullNameDirection: { visibility: 'readOnly' },
  },
  LithoStratTreeDefItem: {
    fullNameSeparator: { whiteSpaceSensitive: true },
  },
};

/*
 * All required fields are unhidden, unless they are overwritten to "hidden".
 * ReadOnly relationships are removed.
 *
 * These apply to Query Builder, Workbench, Leaflet and Specify Network
 */
const fieldOverwrites: typeof globalFieldOverrides = {
  common: {
    timestampCreated: { visibility: 'hidden' },
    timestampModified: { visibility: 'hidden' },
    createdByAgent: { visibility: 'hidden' },
    modifiedByAgent: { visibility: 'hidden' },
    collectionMemberId: { visibility: 'hidden' },
    rankId: { visibility: 'hidden' },
    definition: { visibility: 'hidden' },
    definitionItem: { visibility: 'hidden' },
    orderNumber: { visibility: 'hidden' },
    isPrimary: { visibility: 'hidden' },
    isHybrid: { visibility: 'hidden' },
    isAccepted: { visibility: 'hidden' },
    fullName: { visibility: 'readOnly' },
  },
  Agent: {
    agentType: { visibility: 'optional' },
  },
  CollectionObject: {
    collectionObjectType: { visibility: 'optional' },
  },
  CollectionObjectGroupType: {
    type: { visibility: 'optional' },
  },
  CollectionObjectGroupJoin: {
    precedence: { visibility: 'optional' },
    isSubstrate: { visibility: 'optional' },
  },
  LoanPreparation: {
    isResolved: { visibility: 'optional' },
  },
  Locality: {
    srcLatLongUnit: { visibility: 'optional' },
  },
  PrepType: {
    isLoanable: { visibility: 'readOnly' },
  },
  Determination: {
    preferredTaxon: { visibility: 'readOnly' },
    isCurrent: { visibility: 'hidden' },
  },
};

/*
 * Same as fieldOverwrites, but matches with fieldName.endsWith(key),
 *  instead of fieldName===key.
 * endsWithFieldOverwrites are checked against fields in all tables.
 */
const endsWithFieldOverwrites: Partial<
  RR<keyof Tables | 'common', IR<FieldConfigOverwrite>>
> = {
  Attachment: {
    Attachments: { visibility: 'hidden' },
  },
};

// Overwrite SpecifyTable.view
export const tableViews: Partial<RR<keyof Tables, string>> = {
  SpQuery: 'Query',
};

export const getTableOverwrite = (
  tableName: keyof Tables
): TableConfigOverwrite | undefined => tableOverwrites[tableName];

export const getGlobalFieldOverwrite = (
  tableName: keyof Tables,
  fieldName: string
): FieldConfigOverwrite | undefined =>
  f.maybe(globalFieldOverrides[tableName], (fieldOverwrites) =>
    caseInsensitiveHash(fieldOverwrites, fieldName)
  ) ?? caseInsensitiveHash(globalFieldOverrides.common, fieldName);

export const getFieldOverwrite = (
  tableName: keyof Tables,
  fieldName: string
): FieldConfigOverwrite | undefined =>
  f.maybe(fieldOverwrites[tableName], (overwrites) =>
    caseInsensitiveHash(overwrites, fieldName)
  ) ??
  caseInsensitiveHash(fieldOverwrites.common, fieldName) ??
  Object.entries(endsWithFieldOverwrites[tableName] ?? {}).find(([key]) =>
    fieldName.endsWith(key)
  )?.[VALUE] ??
  Object.entries(endsWithFieldOverwrites.common ?? {}).find(([key]) =>
    fieldName.endsWith(key)
  )?.[VALUE];
